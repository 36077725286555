'use client';

import Link from 'next/link';
import { useContext } from 'react';

import { Choose } from '@/components/UtilitiesComponents';
import { AmplitudeContext } from '@/contexts/amplitude';

import styles from '../../Header.module.css';
import MenuDesktopWithSubMenu from '../../shared/MenuDesktopWithSubMenu';
import { MenuItem } from '../../shared/MenuItem';
import { ProductsMenuProps } from './';

export const HeaderLinksWithoutProducts: React.FC<ProductsMenuProps> = ({
  headerLinksWithoutProducts,
  isCustom,
  textColor,
}) => {
  const { analytics } = useContext(AmplitudeContext);

  return (
    <>
      {headerLinksWithoutProducts?.map((link, index) => (
        <Choose key={index}>
          <Choose.When condition={Boolean(link.href)}>
            <Link
              href={link.href ?? ''}
              prefetch={false}
              aria-label={link.title}
              className={`px-16 my-auto font-medium ${textColor} hover:text-stone-500 paragraph-16`}
              onClick={() => {
                analytics?.track({
                  event_type: 'cta stone',
                  event_properties: {
                    name: 'cta stone',
                    description: `Evento disparado quando o usuário clica na opção ${link.title} no menu do site`,
                    section_reference: 'Menu',
                    cta_reference: link.title,
                  },
                });
              }}
            >
              {link.title}
            </Link>
          </Choose.When>
          <Choose.Otherwise>
            <MenuDesktopWithSubMenu
              title={link.title}
              textColor={textColor}
              isCustom={isCustom}
            >
              <div className={styles.menu}>
                <div>
                  <MenuItem
                    pageLink={link.pageLink}
                    className="p-4 !my-0 lg:hover:bg-display-100"
                    classNameTitle="paragraph-16"
                    classNameSubtitle="paragraph-14 !text-display-900"
                    trackTitle={link.title}
                  />
                </div>
              </div>
            </MenuDesktopWithSubMenu>
          </Choose.Otherwise>
        </Choose>
      ))}
    </>
  );
};
