import clsx from 'clsx';

import { machineImage, tonImage } from '@/resources/header';

import styles from '../../Header.module.css';
import MachineImage from '../../shared/MachineImage';
import { MenuItem } from '../../shared/MenuItem';
import { CustomMenuProps, HeaderProductsProps } from '../../types';

interface ProductLinkProps extends HeaderProductsProps, CustomMenuProps {}

export const ProductLinkMenu: React.FC<ProductLinkProps> = ({
  title,
  isCustom,
  textColor,
  sectionMenu,
  sectionMenuRight,
}) => (
  <div
    className={clsx(
      isCustom ? styles.customMenuContainer : styles.menuContainer,
      'relative z-10',
    )}
  >
    <div
      aria-label={title}
      className={`px-16 my-auto font-medium ${textColor} paragraph-16`}
    >
      {title}
    </div>
    <div className={`${styles.menuProducts} left-[calc(-100%-14px)]`}>
      <div className="lg:!px-16 xl:!px-24 pt-24 pl-24 lg:!w-[290px] xl:!w-[338px]">
        <MachineImage {...machineImage} />
        <MachineImage {...tonImage} />
      </div>
      <div className="flex flex-row py-24">
        {sectionMenu?.map((subItem, index) => (
          <MenuItem key={index} {...subItem} trackTitle={title} />
        ))}
      </div>
      <div className="flex flex-col pt-24 bg-display-50 rounded-br-2xl">
        {sectionMenuRight?.map((product, index) => (
          <MenuItem
            {...product}
            key={index}
            trackTitle={title}
            className="bg-display-50"
          />
        ))}
      </div>
    </div>
    <div className={styles.menuOverlay}></div>
  </div>
);
