'use client';

import { useContext } from 'react';

import { If } from '@/components/UtilitiesComponents';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { attendanceHeaderLink, menuData } from '@/resources/header';

import BeStoneButton from '../../shared/BeStoneButton';
import DownloadAppMenu from '../../shared/DownloadAppMenu';
import LogoStone from '../../shared/LogoStone';
import { HeaderProps } from '../../types';
import DesktopCustomerSupport from '../DesktopCustomerSupport';
import DesktopProductsMenu from '../DesktopProductsMenu';

export const DesktopMenu: React.FC<HeaderProps> = ({
  styledButton = 'btn-secondary btn-regular',
  colorLogo,
  isDownloadApp,
  isLogin,
  isButtonDesktop,
  isProductsMenuDesktop,
  isCustomerSupportDesktop,
  customQRCodeLink,
  styledStoneButton,
  ...props
}) => {
  const { analytics } = useContext(AmplitudeContext);
  return (
    <div
      className={`hidden lg:flex items-center mx-auto max-w-screen-xl h-full justify-between`}
    >
      <LogoStone colorLogo={colorLogo} />
      <If condition={Boolean(isProductsMenuDesktop)}>
        <DesktopProductsMenu
          headerProducts={menuData?.headerProducts}
          headerLinksWithoutProducts={menuData?.headerLinksWithoutProducts}
          {...props}
        />
      </If>

      <div className="flex items-center self-stretch">
        <If condition={Boolean(isCustomerSupportDesktop)}>
          <DesktopCustomerSupport
            attendanceHeaderLink={attendanceHeaderLink}
            {...props}
          />
        </If>
        <If condition={Boolean(isDownloadApp)}>
          <DownloadAppMenu customQRCodeLink={customQRCodeLink} {...props} />
        </If>

        <If condition={Boolean(isLogin)}>
          <a
            className={`px-16 ml-16 btn ${styledButton}`}
            onClick={() => {
              analytics?.track({
                event_type: 'cta stone',
                event_properties: {
                  name: 'cta stone',
                  description:
                    'Evento disparado quando o usuário clica na opção Login no menu do site',
                  section_reference: 'Menu',
                  cta_reference: 'Login',
                },
              });
            }}
            href={LINKS.ACCOUNT}
          >
            Login
          </a>
        </If>
        <If condition={Boolean(isButtonDesktop)}>
          <BeStoneButton className={`ml-16 w-max ${styledStoneButton || ''}`} />
        </If>
      </div>
    </div>
  );
};
