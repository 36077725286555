'use client';

import Link from 'next/link';
import React, { useContext, useEffect, useState } from 'react';

import Button from '@/app/components/base/ui/Button';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';

const BeStoneButton: React.FC<{ className?: string; onClick?: () => void }> = ({
  onClick,
  className,
}) => {
  const [url, setUrl] = useState(LINKS.INBOUND);
  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    setUrl(
      window.location.search
        ? LINKS.INBOUND + window.location.search
        : LINKS.INBOUND,
    );
  }, []);

  return onClick ? (
    <Button
      className={`px-16 ${className || 'w-max'}`}
      size="regular"
      onClick={onClick}
      sectionReference="Header"
    >
      Seja Stone
    </Button>
  ) : (
    <Link
      href={url}
      prefetch={false}
      aria-label="Seja Stone"
      className={`btn btn-primary btn-regular px-16 ${className}`}
      onClick={() => {
        analytics?.track({
          event_type: 'cta stone',
          event_properties: {
            name: 'cta stone',
            description:
              'Evento disparado quando o usuário clica na opção Seja Stone no menu do site',
            section_reference: 'Menu',
            cta_reference: 'Seja Stone',
          },
        });
      }}
    >
      Seja Stone
    </Link>
  );
};

export default BeStoneButton;
