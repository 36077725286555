'use client';

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useContext } from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';

export type MachineImageProps = {
  titleDesktop: string;
  titleMobile: string;
  subtitle: string;
  images: {
    web: string;
    tablet: string;
  };
  link: {
    label: string;
    href: string;
    target?: string;
  };
  icon: IconsNames;
  backgroudColor?: string;
};

const MachineImage: React.FC<MachineImageProps> = ({
  titleDesktop,
  titleMobile,
  subtitle,
  images,
  link,
  icon,
  backgroudColor,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  return (
    <Link
      {...link}
      passHref
      prefetch={false}
      onClick={() => {
        analytics?.track({
          event_type: 'cta stone',
          event_properties: {
            name: 'cta stone',
            description: `Evento disparado quando o usuário clicar no card "${titleDesktop}" no menu do site`,
            section_reference: 'menu',
            cta_reference: link.label,
          },
        });
      }}
    >
      <div
        className={classNames(
          'group flex lg:flex-col md:gap-8 justify-start p-16 lg:m-0 mx-16 mb-24 md:!w-[468px] lg:!w-[258px] xl:!w-[290px] align-middle bg-display-100 rounded-md hover:cursor-pointer',
          { [`${backgroudColor}`]: backgroudColor },
        )}
      >
        <div className="relative md:w-[210px] lg:w-auto min-w-[136px] md:h-[144px] lg:!h-[116px] min-h-[88px]">
          <div className="block md:hidden lg:block">
            <Image
              src={images.web}
              fill
              alt="Conheça nossos planos"
              className="object-cover !h-[stretch] rounded-[8px]"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </div>
          <div className="hidden md:block lg:hidden">
            <Image
              src={images.tablet}
              fill
              alt="Conheça nossos planos"
              className="!h-[stretch] rounded-[8px]"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </div>
        </div>

        <div className="flex md:flex-col">
          <div className="hidden lg:flex flex-col lg:rounded-md">
            <p className="pb-8 font-display group-hover:!text-stone-500 heading-6">
              {titleDesktop}
            </p>
            <p className="hidden lg:block paragraph-14">{subtitle}</p>
          </div>

          <p className="lg:hidden self-center pl-16 md:pl-0 md:w-[210px] font-display font-bold leading-[22px] group-hover:text-stone-500 md:group-hover:text-display-900 md:heading-6">
            {titleMobile}
          </p>

          <p className="flex justify-center items-center pt-8 lg:pt-16 pl-0 w-max font-semibold text-stone-500 group-hover:text-stone-700">
            <span className="hidden md:flex items-center border border-transparent group-hover:border-b-stone-700">
              <span className="mr-4 font-medium text-current paragraph-16">
                {link.label}
              </span>
              <Icon name={icon} className="mx-auto w-24 h-24 fill-current" />
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MachineImage;
