'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { m } from 'framer-motion';
import { FC, useContext, useEffect, useState } from 'react';

import { Choose } from '@/components/UtilitiesComponents';
import ClientOnlyPortal from '@/components/v3/ClientOnlyPortal';
import Icon from '@/components/v3/Icon';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { machineImage, tonImage } from '@/resources/header';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';
import { When } from '@/utils/when';

import styles from '../../Header.module.css';
import { menuMobileDrawerVariants, menuMobileVariants } from '../../helpers';
import BeStoneButton from '../../shared/BeStoneButton';
import MachineImage from '../../shared/MachineImage';
import { MenuItem } from '../../shared/MenuItem';
import MenuWithSubMenu from '../../shared/MenuItemWithSubMenu';
import { MenuProps } from '../../types';
import MobileCustomerSupport from './MobileCustomerSupport';

interface ProductsMenuProps extends MenuProps {
  customQRCodeLink?: string;
  openMenuMobile?: boolean;
  menuHeight?: number;
  showDrawer?: boolean;
  handleToggleDrawer?: () => void;
}

const MobileProductsMenuModal: FC<ProductsMenuProps> = ({
  customQRCodeLink,
  openMenuMobile,
  menuHeight,
  showDrawer,
  headerProducts,
  headerLinksWithoutProducts,
  handleToggleDrawer,
}) => {
  const [adjustLink, setAdjustLink] = useState(
    customQRCodeLink || LINKS.APP_LINK,
  );
  const [utmUrl, setUtmUrl] = useState(
    customQRCodeLink || LINKS.APP_LINK_QR_CODE,
  );
  const [windowHeight, setWindowHeight] = useState(820);
  const isMobile = useResponsiveQuery({ to: 'lg' });
  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    if (isMobile) {
      setAdjustLink(utmUrl);
    }
  }, [isMobile, utmUrl]);

  useEffect(() => {
    setUtmUrl(
      handleAppsflyerUtmUrl(customQRCodeLink || LINKS.APP_LINK_QR_CODE),
    );
  }, [customQRCodeLink]);

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateWindowHeight);
    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);

  useEffect(() => {
    if (openMenuMobile) {
      setWindowHeight(window.innerHeight);
    }
  }, [openMenuMobile]);

  return (
    <>
      <When expr={isMobile}>
        <ClientOnlyPortal selector="#menuMobile">
          <m.div
            animate={openMenuMobile ? 'open' : 'closed'}
            variants={menuMobileDrawerVariants}
            className={`${styles.menuDrawer}`}
            style={{ top: `${menuHeight}px`, height: `${windowHeight - 30}px` }}
            initial="closed"
          >
            <m.div
              animate={openMenuMobile ? 'open' : 'closed'}
              variants={menuMobileVariants}
              className={styles.menuMobile}
              transition={{ ease: 'linear' }}
            >
              <div className="flex overflow-x-auto flex-col h-full">
                <div>
                  <MenuWithSubMenu
                    title={headerProducts?.title ?? ''}
                    subtitle={headerProducts?.subtitle}
                    iconName={headerProducts?.iconName?.name}
                  >
                    <>
                      {headerProducts?.sectionMenu?.map((subItem, index) => (
                        <MenuItem
                          key={index}
                          {...subItem}
                          containerClassName="px-16 pt-16"
                          itemClassName="!p-0 !pr-8"
                          trackTitle={headerProducts?.title}
                        />
                      ))}
                      {headerProducts?.sectionMenuRight?.map((item, index) => (
                        <MenuItem
                          key={index}
                          {...item}
                          containerClassName="px-16 pt-16"
                          itemClassName="!p-0 !pr-8"
                          trackTitle={headerProducts?.title}
                        />
                      ))}
                      <div className="-ml-32 w-[calc(100%+64px)] h-16 bg-display-100" />
                    </>
                  </MenuWithSubMenu>
                </div>
                <div>
                  {headerLinksWithoutProducts?.map((link, index) => (
                    <Choose key={index}>
                      <Choose.When condition={Boolean(link.href)}>
                        <a
                          href={link.href}
                          onClick={() => {
                            analytics?.track({
                              event_type: 'cta stone',
                              event_properties: {
                                name: 'cta stone',
                                description: `Evento disparado quando o usuário clica na opção ${link.title} no menu do site`,
                                section_reference: 'Menu',
                                cta_reference: link.title,
                              },
                            });
                          }}
                        >
                          <div className="flex flex-row items-center py-16 pl-16 w-full text-display-900 bg-display-0">
                            <Icon
                              name={link.iconName?.name ?? 'stone'}
                              className="mr-12 fill-current"
                            />
                            <div className="flex flex-col items-start">
                              <p className="font-semibold">{link.title}</p>
                              <p className="font-normal paragraph-14">
                                {link.subtitle}
                              </p>
                            </div>
                            <hr className="h-1 bg-display-200" />
                          </div>
                        </a>
                      </Choose.When>
                      <Choose.Otherwise>
                        <MenuWithSubMenu
                          title={link.title ?? ''}
                          subtitle={link.subtitle}
                          iconName={link.iconName?.name}
                        >
                          <>
                            <MenuItem
                              trackTitle={link.title}
                              pageLink={link.pageLink}
                              containerClassName="px-16 pb-16"
                              classNameTitle="!font-bold text-base"
                            />
                            <div className="-ml-32 w-[calc(100%+64px)] h-16 bg-display-100" />
                          </>
                        </MenuWithSubMenu>
                      </Choose.Otherwise>
                    </Choose>
                  ))}
                  <MenuWithSubMenu
                    subtitle="Estamos aqui pra te ajudar"
                    title="Atendimento"
                    iconName="question"
                  >
                    <MobileCustomerSupport utmUrl={utmUrl} />
                    <div className="-ml-32 w-[calc(100%+64px)] h-16 bg-display-100" />
                  </MenuWithSubMenu>
                  <div className="pt-16">
                    <MachineImage {...machineImage} />
                    <MachineImage {...tonImage} />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-16 justify-center items-center p-16 pb-[100px] mt-auto w-full bg-display-100">
                  <BeStoneButton
                    onClick={showDrawer ? handleToggleDrawer : undefined}
                    className={`w-full`}
                  />
                  <a
                    className="w-full btn btn-secondary btn-regular"
                    href={adjustLink}
                  >
                    Baixe o App
                  </a>
                </div>
              </div>
            </m.div>
          </m.div>
        </ClientOnlyPortal>
      </When>
    </>
  );
};

export default MobileProductsMenuModal;
