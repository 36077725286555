'use client';

/* eslint-disable tailwindcss/no-custom-classname */
import Link from 'next/link';
import { useContext } from 'react';

import { Choose, If } from '@/components/UtilitiesComponents';
import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';

import { SectionMenuProps } from '../../types';

interface SubItemProps extends SectionMenuProps {
  containerClassName?: string;
  itemClassName?: string;
  classNameTitle?: string;
  classNameSubtitle?: string;
  className?: string;
  trackTitle?: string;
}

export const MenuItem: React.FC<SubItemProps> = ({
  title,
  pageLink,
  containerClassName,
  className,
  classNameTitle,
  classNameSubtitle,
  trackTitle,
}) => {
  const { analytics } = useContext(AmplitudeContext);

  return (
    <div>
      <If condition={Boolean(title)}>
        <div className={`z-40 ${containerClassName} px-16`}>
          <p className="text-display-600 paragraph-16">{title}</p>
        </div>
      </If>
      {pageLink.map((item, index) => (
        <div className={className} key={index}>
          <Choose>
            <Choose.When condition={Boolean(item.href)}>
              <Link
                href={item.href ?? ''}
                prefetch={false}
                aria-label={title}
                aria-hidden="true"
                className={`group m-16 flex items-center bg-display-0 p-0 px-8 lg:py-12 lg:hover:rounded lg:hover:bg-display-100 ${className}`}
                onClick={() => {
                  analytics?.track({
                    event_type: 'cta stone',
                    event_properties: {
                      name: 'cta stone',
                      description: `Evento disparado quando o usuário clica na opção ${item.title} no menu de ${trackTitle} do site`,
                      section_reference: 'Menu',
                      cta_reference: trackTitle,
                      section_submenu: item.title,
                    },
                  });
                }}
              >
                <div className="!w-24 text-display-900 group-hover:!text-stone-500">
                  <Icon
                    className={`${
                      item.iconName?.name !== 'pounds' && 'fill-current'
                    }`}
                    name={item.iconName.name ?? 'stone'}
                  />
                </div>
                <div className="ml-12 ">
                  <p
                    className={`paragraph-14 xl:paragraph-16 font-medium text-display-900 lg:group-hover:!text-stone-500 ${classNameTitle}`}
                  >
                    {item.title}
                  </p>

                  <p
                    className={`paragraph-14 mt-2 text-display-600 lg:group-hover:!text-display-900 ${classNameSubtitle}`}
                  >
                    {item.subtitle}
                  </p>
                </div>
              </Link>
            </Choose.When>
            <Choose.Otherwise>
              <a
                key={title}
                href={item.href}
                className="flex items-center"
                aria-label={title}
              >
                <Icon className="fill-current" name={'phone'} />
                <div className="ml-12">
                  <div className="flex">
                    <p className="font-semibold">
                      {title}{' '}
                      <If condition={Boolean(item.soon)}>
                        <span className="text-display-600 font-400 paragraph-14">
                          (em breve)
                        </span>
                      </If>
                    </p>
                  </div>
                  <p
                    className={`paragraph-14 mt-2 ${
                      item.subtitleBold ? 'font-medium' : ''
                    }`}
                  >
                    {item.subtitle}
                  </p>
                </div>
              </a>
            </Choose.Otherwise>
          </Choose>
        </div>
      ))}
    </div>
  );
};
